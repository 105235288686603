import { React, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { ethers } from "ethers";
import { launchpad } from "../../common/abis/launchpad"

function ProgressBarMenu(props) {
  const {item} = props
  const { ethereum } = window;
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const [currentPurchase, setCurrentPurchase] = useState("0")

  useEffect(async() => {
    try {
      let launchpadContract;
      if (item.attributes.PoolAddress) {
        launchpadContract = new ethers.Contract(
          item.attributes.PoolAddress,
          launchpad,
          signer
        );
      }
      let poolSupply;
      if (launchpadContract) {
        poolSupply = await launchpadContract.getPoolInfo();
        setCurrentPurchase(Number(item.attributes.Hardcap) - Number(poolSupply[5].toString()));
      }
      
    } catch (error) {
      console.log(error);
    }
  }, []);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setTimeLeft(calculateTimeLeft());
  //   }, 5000);

  //   return () => clearTimeout(timer);
  // });

  return (
    <div className="progressInfo mb-3">
      <div>
        <p className="progressPercentage">Progress: {((Number(currentPurchase)/Number(item.attributes.Hardcap))*100).toFixed("2")}%</p>
      </div>
      <div className="">
        <ProgressBar variant="success" now={(Number(currentPurchase)/Number(item.attributes.Hardcap))*100} />
      </div>
      <div className="currentProgress">
        <p>
          {Number(currentPurchase).toLocaleString()} {item.attributes.token.data.attributes.TokenName} /{" "}
          {Number(item.attributes.Hardcap).toLocaleString()}{" "}
          {item.attributes.token.data.attributes.TokenName}
        </p>
      </div>
    </div>
  );
}

export default ProgressBarMenu;
