import { useState, useEffect } from 'react'
import { InputGroup, FormControl, Button, Form } from "react-bootstrap";
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import { launchpad as launchpadABI } from "../../common/abis/launchpad";
import { toSmallUnit } from "../../utils/helper/web3helper";
import { abi } from "../../common/abis/abi";

function Buy(props) {
  const { token, launchpad } = props;
  const [buyValue ,setBuyValue] = useState("0")
  const {ethereum} = window;
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const [balance, setBalance] = useState("0")
  const [tokenBalance, setTokenBalance] = useState("0")
  const [tokenName, setTokenName] = useState("")
  const [decimal, setDecimal] = useState(0)
  const [tokenDecimal, setTokenDecimal] = useState(0)
  const [nameOfToken, setNameOfToken] = useState("")
  
  useEffect(async () => {
    const userAddress = await signer.getAddress();
    // const balance = await provider.getBalance(userAddress)
    const currencyContract = new ethers.Contract(launchpad.ExchangeToken, abi, signer);
    const tokenLaunchpadContract = new ethers.Contract(token.TokenAddress, abi, signer);
    const getTokenBalance = await tokenLaunchpadContract.balanceOf(userAddress);
    const getTokenDecimals = await tokenLaunchpadContract.decimals();
    const getNameOfToken = await tokenLaunchpadContract.symbol();
    const getBalance = await currencyContract.balanceOf(userAddress);
    const getDecimals = await currencyContract.decimals();
    const getTokenName = await currencyContract.symbol();
    setDecimal(getDecimals)
    setTokenDecimal(getTokenDecimals)
    setBalance(getBalance.toString())
    setTokenBalance(getTokenBalance.toString())
    setTokenName(getTokenName)
    setNameOfToken(getNameOfToken)
  }, [])
  
  const buyToken = async () => {
    const usdc = {
      // address: '0xbc215584c86151b2e24f5fAb8116923d9f292a11',
      address: launchpad.PoolAddress,
      abi: launchpadABI,
    };
    const userAddress = await signer.getAddress();
    console.log("user", userAddress);
    const lpadContract = new ethers.Contract(usdc.address, usdc.abi, signer);
    const checkSharingRules = await lpadContract.checkSharingRules();
    console.log(checkSharingRules)
    const purchase = await lpadContract.purchase(toSmallUnit(buyValue, launchpad.ExchangeDecimal), {value: ethers.utils.parseEther("0.001")});
    await purchase.wait()
    console.log('kelar cuy', purchase);
  }
  return (
    <div className="countdownBox p-3 mt-3 rounded">
      <p>Buy amount</p>
      <p className="mt-0">Your Balance: {ethers.utils.formatUnits(balance.toString(), decimal)} {tokenName}</p>
      <p className="mt-0">Your {nameOfToken} Balance: {ethers.utils.formatUnits(tokenBalance.toString(), tokenDecimal)} {nameOfToken}</p>
      <div className="inputGroup my-0">
        <InputGroup
          type="number"
          min="0"
          step="1"
          max={launchpad.MaxContribution}
          >
          {/* <img
            src={DEFAULT_IMAGE}
            alt="logo"
            style={{ width: '40px', padding: '8px' }}
          /> */}
          <FormControl
            placeholder="amount BNB"
            type="text"
            className="inputField"
            value={buyValue}
            onChange={(e) => {
              if(typeof Number(e.target.value) === "number" && !isNaN(Number(e.target.value)) && Number(e.target.value) <= launchpad.MaxContribution){
                setBuyValue(e.target.value)
              }
            }}
          />
          <Button
            className="inputField bg-button btn-sm text-white rounded m-1 px-4 py-2"
            id="button-addon2"
            onClick={() => {
              console.log("keclick");
              setBuyValue(launchpad.MaxContribution)
            }}
          >
            Max
          </Button>
        </InputGroup>
      </div>
      <p className="mt-2">You will receive {buyValue ? Number(buyValue).toLocaleString() : 0} {nameOfToken}</p>
      <p className="mt-0">Max: {Number(launchpad.MaxContribution).toLocaleString()} {nameOfToken}</p>
      <Button
        className="inputField mt-2 bg-button btn-sm text-white rounded px-4 py-2"
        id="button-addon2"
        onClick={() => buyToken()}
      >
        Buy
      </Button>
    </div>
  );
}

export default Buy;
