import { applyMiddleware, compose, createStore } from 'redux';

// import createSagaMiddleware from 'redux-saga';
// import { createWrapper } from 'next-redux-wrapper';
import rootReducer from './reducers';
import thunk from 'redux-thunk'

// const sagaMiddleware = createSagaMiddleware();

// const composeEnhancers =
//   typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
//     : compose;

// export const enhancers = composeEnhancers(applyMiddleware(sagaMiddleware));

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;

