import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import radar from "../../assets/Iconsradar.svg";
import hourglass from "../../assets/Iconshourglass.svg";
import doublecheck from "../../assets/Iconsdoublecheck.svg";
import check from "../../assets/Iconscheck.svg";
import add from "../../assets/Iconsadd.svg";
import contract from "../../assets/bscScanLogo.svg";
import greenWebsite from "../../assets/greenGlobe.svg";
import greenWhitepaper from "../../assets/greenWhitepaper.svg";
import bnb from "../../assets/bnbLogo.svg";
import eth from "../../assets/ethLogo.svg";
import "./home.css";
import ProgressBarMenu from "../../components/progressBarMenu/progressBarMenu";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getProjectsList } from "./storage/homeActions";
import api from "../../utils/network/baseUrl.utils";
import moment from "moment";
import { ethers } from "ethers";
import { launchpad as launchpadABI } from "../../common/abis/launchpad";
import { useNavigate } from "react-router";

function Home(props) {
  let navigate = useNavigate();
  const targetDate = "03/10/2022";
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.homeReducers.projects);
  const { ethereum } = window;
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();

  const calculateTimeLeft = () => {
    let diff =
      new Date(props.targetDate ? props.targetDate : targetDate) - new Date();

    let timeLeft = {};

    if (diff > 0) {
      timeLeft = {
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((diff / 1000 / 60) % 60),
        seconds: Math.floor((diff / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 50000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    dispatch(getProjectsList());
  }, []);

  const statusObj = {
    OnSale: {
      text: "On Sale",
      img: radar,
    },
    ComingSoon: {
      text: "Coming Soon",
      img: hourglass,
    },
    Ended: {
      text: "Ended",
      img: doublecheck,
    },
  };

  const chainObj = {
    1: {
      img: bnb,
      cur: "BNB",
      scan: "bscscan.com",
    },
    2: {
      img: eth,
      cur: "ETH",
      scan: "etherscan.io",
    },
  };

  const OnDetail = (link) => {
    navigate(link);
  };

  return (
    <>
      <Container>
        {/* <Box className="homeMain">
        <Box className="addLaunchpad">
          <button className="addLaunchpadButton">
            <img src={add} alt="addLaunchpadButton" />
            Add Launchpad
          </button>
        </Box>
        <Box className="projectsBox"></Box>
      </Box> */}
        <Row>
          {projects.length ? (
            projects[0].data.map((item, idx) => {
              const itemAttr = item.attributes;
              const pool = projects[1].data.filter((token) => {
                return token.id === itemAttr.token.data.id;
              })[0];
              const poolAttr = pool.attributes;
              // const checkSharingRules = await lpadContract.checkSharingRules();
              if (itemAttr.VerifiedAt !== null) {
                return (
                  <Col lg={4} key={idx}>
                    <div
                      onClick={() => {
                        OnDetail('detail/' + item.id);
                      }}
                      role="button"
                      className="individualBox p-4 rounded pe-pointer"
                      tabindex="0"
                    >
                      <div className="statuses">
                        <div
                          className={`status${itemAttr.StatusType[1]} d-flex align-items-center`}
                        >
                          <img
                            src={statusObj[itemAttr.StatusType[1]].img}
                            alt=""
                          />
                          <p className="statusText">
                            {statusObj[itemAttr.StatusType[1]].text}
                          </p>
                        </div>
                        <div className="status2">
                          <img
                            src={
                              itemAttr.StatusType[0] === 'Verify' ? check : ''
                            }
                            alt=""
                          />
                          <p className="statusText">
                            {itemAttr.StatusType[0] === 'Verify'
                              ? 'Audited'
                              : 'Not audited yet'}
                          </p>
                        </div>
                      </div>
                      <Box className="logoAndTitleBox">
                        <Box className="logoBox">
                          <div style={{ position: 'relative' }}>
                            {poolAttr.TokenLogo.data !== null ? (
                              <img
                                className="w80"
                                src={
                                  api.BASE_URL +
                                  poolAttr.TokenLogo.data.attributes.url
                                }
                                alt="token logo"
                              />
                            ) : (
                              <img src={''} />
                            )}
                            <img
                              src={
                                chainObj[poolAttr.chain.data.attributes.ChainID]
                                  .img
                              }
                              alt="chain logo"
                              style={{
                                position: 'absolute',
                                bottom: '0',
                                right: '0',
                                height: '40px',
                              }}
                            />
                          </div>
                        </Box>
                        <Box className="titleBox">
                          <p className="titleBox1">
                            ${itemAttr.token.data.attributes.TokenSymbol}
                          </p>
                          <p className="titleBox2">
                            {itemAttr.token.data.attributes.TokenName}
                          </p>
                        </Box>
                        <Box className="otherLogoBox">
                          <a
                            href={`https://${
                              chainObj[poolAttr.chain.data.attributes.ChainID]
                                .scan
                            }/token/${
                              itemAttr.token.data.attributes.TokenAddress
                            }`}
                          >
                            <img src={contract} alt="contract" />
                          </a>
                          <a href={itemAttr.token.data.attributes.Link.Website}>
                            <img src={greenWebsite} alt="website" />
                          </a>
                          <a
                            href={
                              itemAttr.token.data.attributes.Link.Whitepaper
                            }
                          >
                            <img src={greenWhitepaper} alt="whitepaper" />
                          </a>
                        </Box>
                      </Box>
                      <Box className="launchpadInfo mt-3">
                        <div className="boxDetail">
                          <p className="stackingInfoTitle">Total Raise</p>
                          <span className="h4">$333,333</span>
                        </div>
                        <div className="boxDetail">
                          <p className="stackingInfoTitle">Starts</p>
                          <p>
                            {moment(itemAttr.LaunchDate).format('DD-MMM-YYYY')}
                          </p>
                        </div>
                        <div className="boxDetail">
                          <p className="stackingInfoTitle">Price</p>
                          <p>
                            1{' '}
                            {
                              chainObj[poolAttr.chain.data.attributes.ChainID]
                                .cur
                            }{' '}
                            ={' '}
                            {(
                              itemAttr.token.data.attributes.TokenTotalSupply /
                              itemAttr.Hardcap
                            ).toLocaleString()}{' '}
                            {itemAttr.token.data.attributes.TokenName}
                            {item.attributes.token.data.attributes.TokenName}
                          </p>
                        </div>
                      </Box>
                      <ProgressBarMenu item={item} />
                    </div>
                  </Col>
                );
              }
            })
          ) : (
            <>
              <h1>Empty</h1>
            </>
          )}
        </Row>
      </Container>
    </>
  );
}

export default Home;
