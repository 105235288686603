import React from "react";
import claimLogo from "../../assets/card-receive.svg";
import hourglassLogo from "../../assets/Iconshourglass.svg";

function ClaimButton(props) {
  const { item } = props;
  console.log(item, "@6");
  return (
    <div
      style={{
        background:
          "linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%)",
        opacity: 0.5,
      }}
      className="rounded d-flex justify-content-center py-3 my-3 align-items-center"
    >
      {item.StatusType[1] === "ComingSoon" ? (
        <>
          <img src={hourglassLogo} alt="coming soon" />
          <span className="text-white h6 ml-2 mb-0">Coming Soon</span>
        </>
      ) : (
        <></>
      )}
      {item.StatusType[1] === "OnSale" ? (
        <>
          <img src={claimLogo} alt="on sale" />
          <span className="text-white h6 ml-2 mb-0">Claim ()</span>
        </>
      ) : (
        <></>
      )}
      {item.StatusType[1] === "Ended" ? (
        <>
          <img src={claimLogo} alt="on sale" />
          <span className="text-white h6 ml-2 mb-0">Claim ()</span>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ClaimButton;
