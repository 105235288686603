import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import EthereumProvider from '@walletconnect/ethereum-provider';
import { Link } from "react-router-dom";

function Navs() {
  const [isConnected, setIsConnected] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(true);

  const providerOptions = {
    injected: {
      display: {
        logo: 'data:image/gif;base64,INSERT_BASE64_STRING',
        name: 'Metamask',
        description: 'Connect with the provider in your Browser',
      },
      package: null,
    },
    walletconnect: {
      package: EthereumProvider,
      options: {
        rpc: {
          custom: {
            56: 'https://rpc-bsc-main-01.3tokendigital.com/rpc',
            56: 'https://bsc-dataseed1.ninicoin.io',
            56: 'https://bsc-dataseed1.defibit.io',
            97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
            97: 'https://data-seed-prebsc-2-s2.binance.org:8545/',
            97: 'https://data-seed-prebsc-1-s3.binance.org:8545/',
            97: 'https://data-seed-prebsc-2-s3.binance.org:8545/',
          },
        },
        chainId: 97,
        network: 'binance',
        bridge: 'https://bridge.walletconnect.org',
        qrcodeModalOptions: {
          mobileLinks: ['trust', 'metamask', 'neftipedia'],
        },
      },
    },
  };
  const web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true, // optional
    providerOptions, // required
    disableInjectedProvider: false,
  });

  const init = async () => {
    if (web3Modal.cachedProvider) {
      const instance = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(instance);
      window.$web3 = provider;
      setIsConnected(true);
      setIsDisconnected(false);
    }
  };

  useEffect(() => {
    init();
    console.log("init jalan lo")
  }, []);

  const disconnectWallet = async () => {
    await web3Modal.clearCachedProvider();
    window.$web3 = null;
    setIsDisconnected(true);
    setIsConnected(false);
  };

  const connetWallet = async () => {
    const instance = await web3Modal.connect();
    if(instance){
      setIsDisconnected(false);
      setIsConnected(true);

      const provider = new ethers.providers.Web3Provider(instance);
      window.$web3 = provider;
      console.log('Connect', await window.$web3.listAccounts());
    }
  };

  return (
    <Navbar
      className="py-4"
      collapseOnSelect
      expand="lg"
      bg="transparent"
      variant="dark"
    >
      <Container>
        <Link className="navbar-brand" to={'/'}>
          Launchpad
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="#features">Pools 🔥</Nav.Link>
            <Nav.Link href="#pricing">FAQ</Nav.Link> */}
          </Nav>
          <Nav>
            <Button
              onClick={connetWallet}
              className={isConnected ? 'd-none' : 'd-inline'}
            >
              Connect Wallet
            </Button>
            <Button
              onClick={disconnectWallet}
              className={isDisconnected ? 'd-none' : 'd-inline'}
            >
              Disconnect
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navs;
